import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import "./_siteTitle.scss";

interface ISiteTitle {
    isDark: boolean;
}

export default function SiteTitle(props: ISiteTitle) {
    const { isDark } = props;

  return (
      <p className="siteTitle" style={{ color: isDark ? '#111' : '#fff' }}>
        <span>pinkmoon </span> coffee
      </p>
  )

}