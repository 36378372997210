/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React, { ReactElement } from "react"
import { Container, Row, Col } from "react-bootstrap"
import Header from "../header/header";
import { Link } from "gatsby";

interface ILayout {
  children: ReactElement | ReactElement[];
  pageName: string;
  siteName: string;
}


/**
 * Layout is the overall layout container that encapsulates the entire application.
 * It includes any navigation, sidebar
 * 
 * @param props 
 */
export default function Layout(props: ILayout) {
  const { children, pageName, siteName } = props;

  return (
      <>
        <Header 
          siteName={siteName} 
          pageName={pageName}  />
        <Container fluid className="px-0 main">
          <Row noGutters>
            <Col>
              <Container className="mt-5">
                <main>{children}</main>
              </Container>
            </Col>
          </Row>
        </Container>
        <div className="footerArea">
        </div>
        <Container className="footerArea" fluid>
          <Row noGutters>
            <Col className="footer-col">
              <footer>
                <span>
                  © {new Date().getFullYear()} pinkmoon coffee
                </span>
              </footer>
            </Col>
          </Row>
        </Container>
    </>
  )
}
