import React from "react"
import { Link } from "gatsby"

import { Navbar, Nav, Container, Row, Col } from "react-bootstrap"
import "./_navbar.scss"
import SiteTitle from "../SiteTitle/siteTitle"

interface INavProps {
  siteName: string;
}

export default function CustomNavbar (props: INavProps) {
  const {siteName} = props;
  
  return (
    <>
      <Navbar variant="dark" expand="lg" id="site-navbar">
        <Container>
              <a href="https://pinkmooncoffee.com" target="_blank" className="link-no-style">
                <Navbar.Brand as="span">
                  <SiteTitle isDark={false} />
                </Navbar.Brand>
              </a>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav style={{width: '100%'}} activeKey={siteName}>
                  <ul style={{width: '100%', textAlign: 'right'}}>
                  </ul>
                </Nav>
              </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  )
}
