import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import CustomNavbar from '../navBar/navBar';
import './header.scss';

interface IHeader {
    siteName: string;
    pageName: string;
}

export default function Header(props: IHeader) {
    const {siteName, pageName} = props;

    return (
        <>
            <CustomNavbar 
                siteName={siteName}></CustomNavbar>
        </>
    )
}